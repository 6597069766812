import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  FindingScanningDropContainer,
  FindingScanningSectionContainer,
  FindingScanningSectionSecondText,
  // FindingScanningSectionNumberContainer,
  FindingScanningSectionText,
  FindingScanningStartContainer,
  SubSectionButton,
  SubSectionContainer,
  SubSectionText,
  
} from "./FindingScanningSingleSection.styled";
import SubjectOrderNumber from "../SubjectOrderNumber/SubjectOrderNumber";
import Tooltip from "../../../../../Tooltip/Tooltip";
// import DeleteIcon from "../../../../../Icon/Icons/DeleteIcon";
// import { statusTableConstants } from "../../../../../../constants/findingStatusConstants";
import { useDispatch, useSelector } from "react-redux";
import { selectIsFindingSettingsChanged } from "../../../../../../store/selectors/findingsSelectors";
import {
  removeFindingScanningSubject,
  replaceFindingSubjectOrderNumber,
  setFindingsChanged,
} from "../../../../../../store/actions/findings/findingsActions";
import { useDrag, useDrop } from "react-dnd";
import { FINDING_SUBJECT_DND } from "../../../../../../constants/dndConstants";
import { useRef } from "react";
import FindingScanningMenu from "../../FindingScanningMenu/FindingScanningMenu";
import { useTranslation } from "react-i18next";
// import { useState } from "react";
// import { useCallback } from "react";

const FindingScanningSingleSection = (props) => {
 
  const containerRef = useRef(null);
  //const [selectedSubSectionId, setSelectedSubSectionId] = useState(null);
  const [selectedScanning, setSelectedScanning] = useState(null)
  const [canDrop, setCanDrop] = useState(false);
  const { t } = useTranslation();
  const [{ isDragging }, drag, preview] = useDrag({
    type: FINDING_SUBJECT_DND,
    item: () => {
      return { orderNumber: props?.section?.orderNumber?.value };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: !props?.notEditable,
  });

  const onDrop = (dropItem) => {
    if (dropItem?.orderNumber === props?.section?.orderNumber?.value) return;
    dispatch(
      replaceFindingSubjectOrderNumber({
        firstOrderNumber: dropItem?.orderNumber,
        secondOrderNumber: props?.section?.orderNumber?.value,
      })
    );
  };

  const hoverTimeoutFunction = useCallback(
    () => setCanDrop(false),
    [setCanDrop]
  );

  const [{ handlerId }, drop] = useDrop(
    {
      accept: FINDING_SUBJECT_DND,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        };
      },
      drop: onDrop,
      hover(item, monitor) {
        if (!containerRef.current) {
          return;
        }
        const dragIndex = item?.orderNumber - 1;
        const hoverIndex = props?.section?.orderNumber - 1;
        if (dragIndex === hoverIndex) {
          return;
        }
        const hoverBoundingRect =
          containerRef.current?.getBoundingClientRect?.();
        const clientOffset = monitor.getClientOffset?.();
        setCanDrop(
          clientOffset?.y > hoverBoundingRect?.top &&
            clientOffset?.y < hoverBoundingRect?.bottom
        );
        if (hoverTimeout) clearTimeout(hoverTimeout);
        hoverTimeout = setTimeout(hoverTimeoutFunction, 200);
      },
    },
    []
  );
  drop(containerRef);
  let hoverTimeout;

  const dispatch = useDispatch();
  const isFindingChanged = useSelector(selectIsFindingSettingsChanged);
  const handleDeleteSubject = (event, subjectId) => {
    event.stopPropagation();
    if (!isFindingChanged) dispatch(setFindingsChanged(true));
    dispatch(removeFindingScanningSubject(subjectId));
  };

  const handleSubSectionClick = (subSectionId) => {
  
   // setSelectedSubSectionId(subSectionId);
    props.onSetSelectedSubSectionId(subSectionId)
    props.handleSectionClick(subSectionId);
  };

  useEffect(() => {
  
    if (props.isOpen === selectedScanning  && props.section?.sections?.length > 0) {
      // Selektuje prvu sekciju kad se dropdown otvori
      const firstSubSection = props.section.sections[0];
    
    //  setSelectedSubSectionId(firstSubSection.id);
      props.onSetSelectedSubSectionId(firstSubSection.id)
      //props.handleSectionClick(firstSubSection.id);
    }
  }, [props.isOpen, selectedScanning]);
 
  return (
    <FindingScanningDropContainer
      data-handler-id={handlerId}
      ref={containerRef}
      $canDrop={canDrop}
    >
      <FindingScanningSectionContainer
        ref={preview}
        key={props?.section?.id}
        $isDragging={isDragging}
        $selected={props?.selected}
        onClick={() => {
       
          setSelectedScanning(props.section.id); //Id predmeta ispitivanja 
          props?.handleChangeSection(props?.section?.id)
         
        }
        }
      >
        <>
        <FindingScanningStartContainer>
          {!props?.scanningSection && (
            <SubjectOrderNumber
              ref={drag}
              number={props?.section?.orderNumber?.value + 1}
              notEditable={props?.notEditable}
            />
          )}

          <Tooltip
            title={
              props?.section?.scanningSubject?.name?.length > 0
                ? props?.section?.specificName?.value
                : ""
            }
          >
            <FindingScanningSectionText>
              {/* {props?.section?.title} */}
              {props?.section?.specificName?.value || props?.section?.title}
            </FindingScanningSectionText>
            {!props?.scanningSection && (
              <FindingScanningSectionSecondText>
                {t("scanningObjects.type") + ": "}
                {props?.section?.scanningSubject?.name || props?.section?.title}
              </FindingScanningSectionSecondText>
            )}
          </Tooltip>
          {!props?.scanningSection && !props?.notEditable && (
        <FindingScanningMenu
          sectionId={props?.section?.id}
          onClick={(event) =>{ event.stopPropagation(); handleDeleteSubject(event, props?.section?.id)}}
          sectionData={props?.section}
        />
      )}
        </FindingScanningStartContainer>
        
        </>
        {/* {props?.stateId === statusTableConstants.inProgress.id &&
          !props?.notEditable && (
            <FindingScanningSectionNumberContainer
              onClick={(event) =>
                handleDeleteSubject(event, props?.section?.id)
              }
            >
              <DeleteIcon />
            </FindingScanningSectionNumberContainer>
          )} */}
           </FindingScanningSectionContainer>
      
       {props.selected && !props.closeScanning &&
       (<SubSectionContainer >
       {props.section?.sections?.length > 0 ? (
         props.section.sections.map((subSection, index) => (
           <SubSectionButton 
             key={index} 
             onClick={() => handleSubSectionClick(subSection.id)}
             isSelected = {props.selectedSubSectionId  === subSection.id}
             
           >
            <SubSectionText> {subSection.title}</SubSectionText>
           </SubSectionButton>
         ))
       ) : (
         <div></div>
       )}
     </SubSectionContainer>
       )}     
    </FindingScanningDropContainer>
    
  );
};

FindingScanningSingleSection.propTypes = {
  section: PropTypes.object,
  selected: PropTypes.bool,
  stateId: PropTypes.number,
  notEditable: PropTypes.bool,
  handleChangeSection: PropTypes.func,
  scanningSection: PropTypes.bool,
  isOpen: PropTypes.bool,
  handleSectionClick: PropTypes.func,
  data: PropTypes.array,
  
  onSetSelectedSubSectionId: PropTypes.func,
  selectedSubSectionId: PropTypes.any,
  closeScanning: PropTypes.bool
};

export default FindingScanningSingleSection;
